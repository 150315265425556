import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="cool-horse.png" className="App-logo" alt="logo" />
        <p>Now thats one cool horse</p>
      </header>
    </div>
  );
}

export default App;
